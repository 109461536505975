import React from 'react'
import { Section, Container, Box } from 'components'

const Separator = ({}) => {
  return (
    // <Section spacing="large">
    <Container>
      <Box
        sx={{
          border: '1px solid',
          color: 'colorBlue10',
        }}
      />
    </Container>
    // </Section>
  )
}

export default Separator
