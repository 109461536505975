import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import Layout from 'components/Layout'
import { Link, Text } from 'components'

import PageTitleWithImage from 'screens/Home/components/PageTitleWithImage'
import FinancialSolutions from 'screens/Home/components/FinancialSolutions'
import PageSnippets from 'screens/Home/components/PageSnippets'
import FAQTeilverkauf from 'screens/Home/components/FAQTeilverkauf'
import Testimonials from 'screens/Home/components/Testimonials'
import Separator from 'screens/Home/components/Separator'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import SimpleTextBanner from 'screens/Home/components/SimpleTextBanner'
import ContactFullWidth from 'screens/Home/components/ContactFullWidth'

import { useTranslate } from 'context/location'

const Page = (props) => {
  const translate = useTranslate()

  const {
    featuredArticles: { articles: featuredArticles },
    houseCoinsImage,
    salesImage,
    teilverkaufImage,
    salesImage2,
    bambusHouseImage,
    contactFullWidthImage,
  } = useStaticQuery(
    graphql`
      query {
        featuredArticles: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "finanzierung" }
        ) {
          articles {
            ...Article
          }
        }
        salesImage: file(
          relativePath: { eq: "sales-photos/sales_team-1.jpeg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        houseCoinsImage: file(relativePath: { eq: "house-coins-sprouts.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        teilverkaufImage: file(relativePath: { eq: "gold-sparschwein.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        salesImage2: file(relativePath: { eq: "people-at-desk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bambusHouseImage: file(relativePath: { eq: "bambus-haus.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        contactFullWidthImage: file(
          relativePath: { eq: "business-man-1.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title: 'Finanzierungslösungen mit Bambus',
        description:
          'Wir finden die passende Lösung für Sie und Ihr Haus oder Ihre Wohnung. Wir beraten zu Hypotheken oder Alternativen und bieten bei Bedarf den Immobilien Teilverkauf an.',
      }}
    >
      {/* Soft hyphenation is used to prevent forced breaking */}
      <PageTitleWithImage
        title={<>Finanzierungs&shy;lösungen besonders einfach mit Bambus</>}
        gatsbyFluidImage={houseCoinsImage.childImageSharp.fluid}
        ctaButtonID="Finanzierung--PageTitleWithImage"
        alt="Finanzierungs­lösungen besonders einfach mit Bambus"
      />
      <FinancialSolutions
        items={[
          {
            title: 'Für Eigentümer',
            description:
              'Wir kaufen bis zu 50% von Einfamilienhäusern in ganz Österreich',
          },
          {
            title: 'Klarer Prozess',
            description:
              'Sie erhalten schnell eine Antwort, ob unser Angebot für Sie passend ist',
          },
          {
            title: 'Transparente Entscheidung',
            description:
              'Unser Angebot basiert auf einem aktuellen Gutachten eines gerichtlich beeideten unabhängigen Gutachters',
          },
          {
            title: 'Komplett unverbindlich',
            description:
              'Bis zum Vertragsabschluss können Sie jederzeit Ihre Meinung ändern – für Sie fallen keine Kosten an',
          },
        ]}
        bulletPointsTitle="Deshalb ist Bambus die richtige Wahl"
        bulletPointsList={[
          'Sie wählen den Verkaufsbetrag',
          'Sie bewohnen weiterhin und unverändert Ihr Haus',
          'Sie und Ihre Erben können jederzeit zurückkaufen',
          'Sie entscheiden weiterhin über Renovierungen',
          'Kostenloses Beratungsgespräch',
          'Kostenloses Bewertungsgutachten',
          'Wir stehen Ihnen beratend zur Seite',
          'Wir sind in ganz Österreich aktiv',
        ]}
        ctaButtonID="Finanzierung--FinancialSolutions"
      />
      <PageSnippets
        snippets={[
          {
            id: 1,
            title: 'Teilverkauf',
            description:
              'Entdecken Sie weitere Optionen für mehr finanzielle Flexibilität in dem Sie einen Teil Ihrer Immobilie verkaufen',
            link: '/',
            gatsbyFluidImage: teilverkaufImage.childImageSharp.fluid,
          },
          {
            id: 2,
            title: 'Unsere Werte',
            description: 'Einfach, sicher & transparent',
            link: '/werte',
            gatsbyFluidImage: salesImage.childImageSharp.fluid,
            imagePosition: 'center 65%',
          },
        ]}
      />
      <ContactFullWidth
        email="beratung@bambus.io"
        phoneNumber={translate({
          DE: '+49 30 837 960 40',
          AT: '+43 800 00 7171',
          _: '+43 800 00 7171',
        })}
        gatsbyFluidImage={contactFullWidthImage.childImageSharp.fluid}
        textPinkButton="Jetzt Teilverkaufsangebot anfragen"
        directContactText={translate({
          DE: 'Gerne können Sie uns auch direkt kontaktieren:',
          AT: 'Kontaktieren Sie uns gerne:',
          _: 'Gerne können Sie uns auch direkt kontaktieren:',
        })}
        imagePosition={[
          '25px 0px',
          '100px 0px',
          '200px 0px',
          '240px 0px',
          '500px 40%',
        ]}
        ctaBlueButtonID="Teilverkauf--ContactFullWidth-Blue"
        leadModalTypeBlueButton="teilverkauf_callback"
        ctaPinkButtonID="Teilverkauf--ContactFullWidth-Pink"
        leadModalTypePinkButton="teilverkauf"
        imageTransform={['scale(1.2)', 'scale(1)', 'scale(1)']}
      />
      <PageSnippets
        title="Sie wollen mehr über Bambus wissen?"
        snippets={[
          {
            id: 1,
            title: 'Top Beratung',
            description:
              'Gemeinsam finden wir Ihre optimale Finanzierungslösung',
            link: '/beratung',
            gatsbyFluidImage: salesImage2.childImageSharp.fluid,
            imagePosition: 'center 65%',
          },
          {
            id: 2,
            title: 'Über Bambus',
            description: 'Unsere Mission - unsere Vision - unsere Lösungen',
            link: '/team',
            gatsbyFluidImage: bambusHouseImage.childImageSharp.fluid,
          },
        ]}
      />
      <Separator />
      <SimpleTextBanner
        title="Sie möchten mehr Information zur Kapitalbeschaffung?"
        descriptionText="Wir schicken Ihnen gerne per Mail mehr Informationen zur Kapitalbeschaffung bei Bambus. In unserem Infoblatt finden Sie eine genaue Beschreibung des Prozesses bei Bambus."
        leadModalType="reduced"
        signupReason="information"
        textCTA="Infoblatt beantragen"
        ctaButtonID="Finanzierung--SimpleTextBanner"
      />
      <Separator />
      <FAQTeilverkauf
        title="Sie suchen mehr Informationen zum Thema Liquidität?"
        subheading="Hier finden Sie einige FAQs & Definitionen – einfach erklärt!"
      />
      <Blog
        title="Tauchen Sie in unseren Ratgeber ein"
        articles={featuredArticles}
        subheadingElement={
          <Text textAlign="center" color="colorBlue90">
            Diese und weitere Artikel zum Thema Finanzierung finden Sie in
            unserem{' '}
            <Link as={RouterLink} to="/ratgeber">
              Ratgeber
            </Link>
          </Text>
        }
      />
      <Testimonials showCTA ctaButtonID="Finanzierung--Testimonials" />
      <Map />
    </Layout>
  )
}
export default Page
