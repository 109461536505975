import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Button,
  Heading,
  Text,
} from 'components'

import Accordion from 'components/Accordion'

import { useLocation } from 'context/location'

const Faq = ({
  titleID = null,
  title = '',
  subheading = '',
  ctaText = 'Alle Fragen & Antworten',
  backgroundColor = 'white',
}) => {
  const {
    faqs: { nodes: faqs },
  } = useStaticQuery(
    graphql`
      query {
        faqs: allContentfulFeaturedFaq(
          filter: { category: { slug: { eq: "teilverkauf" } } }
          sort: { fields: orderNumber, order: ASC }
        ) {
          nodes {
            id
            country
            question
            answer {
              json
            }
          }
        }
      }
    `
  )

  const { countryCode } = useLocation()

  /**
   * Logic to filter out FAQs based on country:
   * 1. If there's no country specified in FAQ, show it
   * 2. Otherwise, if the app's country is DE or AT then show the FAQ if it's for that specific country
   * 3. Otherwise, if the app's country is different than DE or AT, only show the FAQ if for AT (as we show the AT faqs for any other country)
   *
   * Note: we don't show all DE and AT FAQs for countries other than DE or AT because some of the FAQs might be semi duplicates with different wording (specific for AT & DE)
   */
  const countrySpecificFAQs = faqs.filter(({ country: faqCountry }) => {
    if (faqCountry === null) {
      return true
    } else {
      if (countryCode === 'DE' || countryCode === 'AT') {
        return faqCountry === countryCode
      } else {
        return faqCountry === 'AT'
      }
    }
  })

  return (
    <Section spacing="large" bg={backgroundColor}>
      <Container>
        <Section.Header>
          {titleID && (
            <Box
              id={titleID}
              sx={{
                position: 'relative',
                top: [-64 - 20, null, null, -88 - 35],
              }}
            />
          )}
          <Heading.H2 textAlign="center" color="colorBlue90">
            {title}
          </Heading.H2>
          {subheading && (
            <Text textAlign="center" mt={3} color="colorBlue90">
              {subheading}
            </Text>
          )}
        </Section.Header>

        <Section.Body>
          <Row justifyContent="center">
            <Column size={[1, 21 / 24]}>
              <Box bg="colorBlue2">
                <Accordion
                  questions={countrySpecificFAQs}
                  slug="haeufige-fragen"
                />
              </Box>
            </Column>
          </Row>
        </Section.Body>

        <Section.Footer textAlign="center">
          <Button
            variant="outline"
            as={RouterLink}
            py={1}
            to="/faq/haeufige-fragen"
          >
            {ctaText}
          </Button>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Faq
